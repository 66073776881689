import { createRouter, createWebHashHistory } from 'vue-router'

const loginAuth = async  (to, from, next) =>{
	var token = localStorage.getItem('token');
	if(token!=null) next()
	else next('/login')
}

const routes = [
	{
		path: '/login',
		name: 'login',
		component: ()=>import('../views/Login'),
	},
	{
		path: '/registro',
		name: 'register',
		component: ()=>import('../views/Register'),
	},
	{
		path: '/Home',
		name: 'Home',
		meta:{ requiresAuth: true },
		beforeEnter: loginAuth,
		component: ()=>import('../views/Home'),
		children:
		[
			{
				path:'/productos',
				name:'Productos',
				component: ()=>import('../views/Products'),
			},
			{
				path:'/productos/:id',
				name:'ProductView',
				component: ()=>import('../views/ProductView'),
				props: route=>{
					const id = Number( route.params.id );
					return isNaN( id ) ? { id: localStorage.getItem('order_view_id') } : { id }
				}
			},
			{
				path:'/carrito',
				name:'Carro',
				component: ()=>import('../views/Cart')
			},
			{
				path: '/checkout',
				name: 'Checkout',
				component: ()=>import('../views/Checkout'),
				props: ( route ) => {
					const order_id = Number( route.params.order_id );
					return  { order_id }
				}
			},
			{
				path:'/pedidos',
				name:'Ordenes',
				component: ()=>import('../views/Orders'),
			},
			{
				path:'/dashboard',
				name:'Dashboard',
				component: ()=>import('../views/Dashboard'),
			},
			{
				path:'/actualizar_perfil',
				name: 'ProfileUpdate',
				meta: { requiresAdmin: true },
				component: ()=>import('../views/ProfileUpdate'),
			},
			{
				path:'/Home',
				name:'HomeScreen',
				// component: ()=>import('../views/HomeScreen'),
				component: ()=>import('../views/Products'),
			},
			{
				path:'/MiCuenta',
				name: 'MyAccount',
				component: ()=>import('../views/Profile'),
			},
			{
				path:'/DatosFiscales',
				name:'TaxData',
				component: ()=>import('../views/TaxDataRegister'),
			},
			{
				path:'/DatosFiscales/Lista',
				name:'TaxDataList',
				component: ()=>import('../views/TaxData'),
			},
			{
				path:'/OrderSummary',
				name:'orderComplete',
				component: ()=>import('../views/OrderSummary'),
				props: ( route ) => {
					const order_id = Number( route.params.order_id );
					return  { order_id }
				}
			},
			{
				path:'/OrderDetails/:order_id',
				name:'OrderDetails',
				component: ()=>import('../views/OrderDetails'),
				props: ( route ) => {
					const order_id = Number( route.params.order_id );
					return  { order_id }
				}
			},
			{
				path:'/Contactos',
				name:'Contacts',
				component: ()=>import('../views/Contacts'),
			},
			{
				path:'/Pago/:order_id',
				name: 'CardPayment',
				component: ()=>import('../views/CardPayment'),
				props: ( route ) => {
					const order_id = Number( route.params.order_id );
					return  { order_id }
				}
			},
			{
				path:'/Incentivos',
				name:'Incentives',
				component: ()=>import('../views/Incentives'),
			},
			
		]
	},
	{
		path:'/ResetPassword',
		name:'ResetPassword',
		component: ()=>import('../views/ResetPassword'),
	},
	{
		path: '/:pathMatch(.*)*',
		meta:{ requiresAuth: true},
		beforeEnter: loginAuth,
		redirect: { name: 'Home' }
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes,
})

router.beforeEach((to, from, next) => {
	if(to.matched.some(record => record.meta.requiresAdmin)) {
		const admin = localStorage.getItem('admin')
		if(admin && admin === '1') {
			next()
		}else{
			next(from)
		}
	}else{
		next()
	}
});

export default router