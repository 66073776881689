<template>
	<div class="main_div" id="script">
		<router-view></router-view>
	</div>
</template>
<script>
import Navbar from './components/Navbar.vue'
export default {
	components: {
		Navbar,
	},
	name: 'Cebada Store',
	setup () {
		return {}
	},
	watch: {
		title: {
			immediate: true,
			handler() {
				document.title = "Cebada Store";
			},
		},
	},
};
</script>
<style>
.main_div{
	padding: 0;
	margin: 0;
	width: 100%;
	min-height: 100vh;
	background-color: rgb(240,240,240) !important;
	padding-bottom: 50px;
}

#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

nav {
	padding: 30px;
}

nav a {
	font-weight: bold;
	color: #2c3e50;
}

nav a.router-link-exact-active {
	color: #42b983;
}


.ui.message:empty{
	display: none;
}
</style>
