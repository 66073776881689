<template>
	<sui-menu class="menu" borderless size="large" stackable>
		<sui-menu-menu position="left">
			<img @click="home()" class="navbar_logo" src="https://i.imgur.com/8cdarjT.png"/>
		</sui-menu-menu>
		<sui-menu-menu position="right" v-if="flag"> 
			<sui-menu-item @click="home()">Inicio</sui-menu-item>
			<sui-menu-item @click="shop()">Comprar</sui-menu-item>
			<sui-menu-item @click="shopCart()"><sui-icon name="shopping cart icon"/>{{ cartSize }}</sui-menu-item>
			<sui-dropdown item :text='name' style="font-weight: bold">
				<sui-dropdown-menu compact>
					<sui-dropdown-item @click="orders()" text="Mis Pedidos" />
					<sui-dropdown-item @click="myAccount()"  text="Mi Perfil" />
					<sui-dropdown-item @click="account()" text="Modificar Perfil" v-if="admin != 0" />
					<sui-dropdown-item @click="TaxDataList()"  text="Datos Fiscales"/>
					<sui-dropdown-item @click="Contacts()"  text="Contactos" v-if="admin != 0" />
					<sui-dropdown-item @click="Incentives()" text="Incentivos" v-if="admin != 0" />
					<sui-dropdown-item @click="logout" text="Logout" />
				</sui-dropdown-menu>
			</sui-dropdown>
		</sui-menu-menu>
	</sui-menu>
</template>

<script>
import { useRouter } from "vue-router";
import { ref, computed, provide, onMounted } from 'vue'

export default {
	props: {
		flag: {
			type: Boolean,
			default: true,
		},
	},
	setup () {
		const router = useRouter()
		const name = ref(localStorage.getItem('contactName'))
		const admin = ref(localStorage.getItem('admin'));
		const cartSize = ref(0);
		
		const logout = async ()=>{
			localStorage.clear();
			return router.push({ name: "login"})
		}
		
		const shopCart = async()=>{
			return router.push({ name: "Carro"})
		}
		
		const shop = async()=>{
			return router.push({ name: "Productos"})
		}
		
		const account = async()=>{
			return router.push({ name: "ProfileUpdate"})
		}
		
		const orders = async()=>{
			return router.push({ name: "Ordenes"})
		}
		
		const home = async()=>{
			return router.push({ name: "Dashboard"})
		}
		
		const myAccount = async()=>{
			return router.push({ name: "MyAccount"})
		}
		
		const TaxDataList = async()=>{
			return router.push({ name: "TaxDataList"})
		}
		
		const Contacts = async()=>{
			return router.push({ name: "Contacts"})
		}
		
		const Incentives = async()=>{
			return router.push({ name: "Incentives"})
		}

		const calcCartSize = ()=>{
			try{
				var cart = localStorage.getItem('cart');
				if(cart===null) cartSize.value = 0;
				else cartSize.value = JSON.parse(cart).length;
			}catch(e){
				cartSize.value = 0;
			}
		}

		onMounted(()=>{
			calcCartSize();
			window.addEventListener('cebada-cart_update', (e)=>{
				calcCartSize();
			});
		})

		return {
			router,
			logout,
			shopCart,
			shop,
			admin,
			cartSize,
			account,
			orders,
			home,
			myAccount,
			name,
			TaxDataList,
			Contacts,
			Incentives,
			calcCartSize,
		}
	},
}
</script>

<style scoped>
.navbar_logo{
	margin-left: 50px;
	height: 50px;
	cursor: pointer;
	object-fit: contain;
}
.menu{
	color: gray !important;
	border-bottom: none !important;
	border-radius: 0 !important;
}
@media screen and (max-width: 760px) {
	.navbar_logo{
		margin-left: 0;
	}
}
</style>